<template>
  <v-autocomplete
    :disabled="isDisabled"
    v-model="idBranchOffice"
    :items="branchOfficeList"
    @change="branchChange"
    :label="branchLabel"
  />
</template>
<script>
import { BranchOfficeRequest } from "@/core/request";

export default {
  props: {
    branchLabel: "",
  },
  data() {
    return {
      idBranchOffice: "",
      branchOfficeList: [],
      isDisabled: false,
    };
  },
  methods: {
    branchChange() {
      this.$emit("branchChange", this.idBranchOffice, true);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentIdBranchOffice(id) {
      console.log(id);
      this.idBranchOffice = id;
    },
    reloadComponentClean() {
      this.idBranchOffice = "";
    },
  },
  created() {
    BranchOfficeRequest.listAllBranchSuppliers().then((response) => {
      let { data } = response;
      this.branchOfficeList = data.map((val) => {
        return {
          value: parseInt(val.value),
          text: val.text,
        };
      });
    });
  },
};
</script>
