var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{key:"footerProps",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.modelTable.content,"height":"auto","fixed-header":"","dense":"","single-line":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"server-items-length":_vm.modelTable.totalElements,"options":_vm.options,"page":_vm.page,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-3 col-xl-3"},[_c('v-select',{staticClass:"br",attrs:{"items":_vm.statusListFilter,"clearable":"","label":"Estatus","data-vv-name":"select"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"disabled":_vm.loading_search,"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.statusText",fn:function(ref){
    var item = ref.item;
return [_c('ChipTableCommonPagination',{attrs:{"status":item.statusShipment,"statusName":item.statusText}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDelete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":_vm.sending,"small":""},on:{"click":function($event){return _vm.deleteRecord(item)}}},'v-icon',attrs,false),on),[_vm._v(" feather icon-trash-2 ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.delete")))])]):_vm._e(),(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(
          _vm.isUpdate && (item.statusShipment == 6 || item.statusShipment == 7)
        )?_c('span',{staticClass:"mr-2",attrs:{"id":"my-icon"}},[_c('i',{staticClass:"feather icon-edit"})]):_vm._e(),(
          _vm.isUpdate && item.statusShipment != 6 && item.statusShipment != 7
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.updateRecord(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-edit"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.isDownloadPDF)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]):_vm._e()]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }