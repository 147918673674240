<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :search="search"
      :items="modelTable.content"
      height="auto"
      fixed-header
      dense
      single-line
      key="footerProps"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-select
              class="br"
              v-model="statusFilter"
              :items="statusListFilter"
              clearable
              label="Estatus"
              data-vv-name="select"
            ></v-select>
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              :disabled="loading_search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusText`]="{ item }">
        <ChipTableCommonPagination
          :status="item.statusShipment"
          :statusName="item.statusText"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDelete">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="sending"
              small
              class="mr-2"
              @click="deleteRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              feather icon-trash-2
            </v-icon>
          </template>
          <span>{{ $t("table.delete") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="isDetail">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>
        <span
          class="mr-2"
          v-if="
            isUpdate && (item.statusShipment == 6 || item.statusShipment == 7)
          "
          id="my-icon"
        >
          <i class="feather icon-edit"></i>
        </span>
        <v-tooltip
          top
          v-if="
            isUpdate && item.statusShipment != 6 && item.statusShipment != 7
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="updateRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-edit"></i>
            </span>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="isDownloadPDF">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";

export default {
  props: {
    headers: {},
    isDelete: false,
    isUpdate: false,
    isDetail: false,
    isDownloadPDF: false,
    isButton: false,
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      permissions: PermissionConstants,
      search: "",
      options: {
        sortDesc: [true],
      },
      loading: false,
      loading_search: false,
      page: 1,
      statusFilter: null,
      statusListFilter: [
        { value: 1, text: "Pendiente de Finalizar" },
        { value: 2, text: "Picking" },
        { value: 3, text: "Validación de Picking" },
        { value: 4, text: "Pendiente de Envío" },
        { value: 5, text: "Tránsito" },
        { value: 6, text: "Recepción" },
        { value: 7, text: "Cancelado" },
      ],
    };
  },
  components: {
    ButtonTableComponent,
    ChipTableCommonPagination,
  },
  methods: {
    /**
     * Un método que se llama cuando cambia la paginación.
     * @method
     */
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      this.pagesAtributes.currentPage = this.options.page - 1;
      this.pagesAtributes.itemsPerPage = this.options.itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    /**
     * Método que se llama cuando se utiliza la barra de búsqueda.
     * @method
     */
    async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    reloadPages() {
      this.page = 1;
    },
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    detail(item) {
      this.$emit("detail", item);
    },
    reloadComponentSending(sending) {
      this.sending = sending;
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    statusFilter() {
      if (this.statusFilter) {
        this.pagesAtributes.sortFilters.statusSelectFilter = this.statusFilter;
      } else {
        delete this.pagesAtributes.sortFilters.statusSelectFilter;
      }
      this.performSearch(this.pagesAtributes);
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}

#my-icon .feather.icon-edit {
  color: #e2e2e2;
}
</style>
