<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("decrease_order.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("decrease_order.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t("title.title_shi") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              :disabled="sending"
              v-if="
                showAction(1) && withPermissionName(permissions.ORD_REA_ADD_03)
              "
              class="btn btn-outline-success"
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i>
              {{ $t("global.create_starting_order") }}
            </button>
            <!--Modificación de boton se hizo de color verde-->
            <button
              v-if="showAction(2) || showAction(3)"
              class="btn btn-outline-success"
              @click="changeAction(1)"
              style="float: right"
            >
              <i class="feather icon-arrow-left mr-1"></i>
              {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <!-- End Breadcrumbbar -->

      <!-- Start Contentbar -->
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="fa fa-asterisk"></i> {{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <DecreaseTableComponent
                v-if="showTable"
                :modelTable="listStartingOrder"
                :headers="decreaseListHeaders"
                :methodPage="listAllShipmentsByTypeDecrease"
                :pagesAtributes="pagesAtributes"
                :isUpdate="withPermissionName(permissions.ORD_REA_EDIT_02)"
                :isDownloadPDF="
                  withPermissionName(permissions.ORD_REA_DL_PFD_04)
                "
                :isDetail="withPermissionName(permissions.ORD_REA_DETA_01)"
                :isDelete="false"
                :isButton="true"
                v-on:updateRecord="updateOrder"
                v-on:downloadPDF="downloadPDF"
                @detail="detail"
                v-on:excelTable="excelTable"
                v-show="showAction(1)"
              />

              <div class="card-body" v-show="showAction(2)">
                <div class="row">
                  <div class="col-12">
                    <h6>
                      <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                      {{ $t("decrease_order.title_select_reason") }}
                    </h6>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <v-autocomplete
                      @change="idOrder != '' && cleanOrder()"
                      :disabled="sending || isUpdate"
                      v-model="shippingReasonModel.shippingReason"
                      :items="shippingReasonList"
                      :label="$t('label.motive')"
                      data-vv-name="select"
                    >
                      <!-- Solo es visible la advertencia si es una orden existente -->
                      <template v-slot:prepend v-if="idOrder != ''">
                        <v-icon color="red">feather icon-alert-triangle</v-icon>
                      </template>
                    </v-autocomplete>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <AllShipmentRequest
                      ref="allShipmentRequest"
                      v-on:branchChangeRequest="allShipmentRequestChange"
                      :alert="alert"
                    />
                  </div>
                  <div
                    v-show="shippingReasonModel.shippingReason != 9"
                    class="col-sm-12 col-md-12"
                  >
                    <AllBranchOfficeComponent
                      ref="branchOfficeDestination"
                      :branchLabel="$t('label.branch_destination')"
                      v-on:branchChange="branchDestinationChange"
                    />
                  </div>
                  <div
                    v-show="shippingReasonModel.shippingReason == 9"
                    class="col-sm-12 col-md-12"
                  >
                    <AllBranchOfficeSupplier
                      ref="branchOfficeDestinationSupplier"
                      :branchLabel="$t('label.branch_destination')"
                      v-on:branchChange="branchDestinationChange"
                    />
                  </div>

                  <div class="col-12 text-right" v-if="idOrder == ''">
                    <button
                      class="btn btn-outline-success"
                      @click="addShipmentTypeDecrease()"
                      :disabled="sending"
                    >
                      <i class="feather icon-plus mr-1"></i>
                      {{ $t("global.add_products") }}
                    </button>
                  </div>

                  <div class="col-12 text-right" v-else>
                    <button
                      class="btn btn-outline-success"
                      type="button"
                      @click="finishDecrease()"
                      :disabled="sending"
                    >
                      <i class="feather icon-check mr-1"></i>
                      {{ $t("global.finish_starting_order") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="showAction(2) && idOrder != ''">
          <div class="col-lg-12 col-xl-12 pt-10">
            <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <ProductsTableComponent
                    ref="tableOrderStock"
                    :headers="orderHeaders"
                    :isDelete="true"
                    v-on:deleteRecord="deleteRecord"
                  />
                  <div class="card-body">
                    <StartingOrderFormComponent
                      ref="startingOrderFormComponent"
                      :getLotsByProduct="getLotsByProduct"
                      :getProductsByFiscalFound="getProductsByFiscalFound"
                      :getLots="getLots"
                      :modelStartingOrder="modelStartingOrder"
                      :addStockInOrder="addStockInOrder"
                      :getProductsBySupplier="getProductsBySupplier"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalOrderProductComponent ref="modalOrderDetail" />
  </div>
</template>

<script>
// Vuex
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import {
  StockRequest,
  ProductResquest,
  FiscalFoundRequest,
  LotRequest,
  ShipmentsRequest,
  ExcelRequest,
  OrderStockRequest,
} from "@/core/request";

import { SwalDelete, SwalConfirm } from "@/core/SwalAlert";

import SelectBranchOffice from "@/common/select/SelectBranchOffice";
import AllBranchOfficeSupplier from "@/common/select/AllBranchOfficeSupplier";
import AllBranchOfficeComponent from "@/common/select/AllBranchOfficeComponent";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ModalConfirmOrderComponent from "@/common/modal/ModalConfirmOrderComponent";
import ModalOrderProductComponent from "@/common/modal/ModalOrderProductComponent";
import AllShipmentRequest from "@/common/select/AllShipmentRequest";
import ModalDeleteProductComponent from "@/common/modal/ModalDeleteProductComponent";

import StartingOrderFormComponent from "@/components/startingorder/StartingOrderFormComponent";
import DecreaseTableComponent from "@/components/startingorder/decrease/DecreaseTableComponent";
import ProductsTableComponent from "@/components/startingorder/ProductsTableComponent";

import { DECREASE, ORDER_DETAIL_ACTION } from "@/core/DataTableHeadersLocale";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "decrease_order.starting_order_reason",

      /* Cabeceras */
      decreaseListHeaders: DECREASE(),
      orderHeaders: ORDER_DETAIL_ACTION(),

      /* General */
      action: 1,
      idUser: "",
      originId: "",
      destinationId: "",
      oldDestinationId: "",
      loading: null,
      isUpdate: false,
      permissions: PermissionConstants,

      /* Modales */
      dialogConfirm: false,

      /* Formulario */
      orderStockProducts: [],
      idShimentRequest: null,
      idOrder: "",

      modelStartingOrder: {
        productsList: [],
        lotList: [],
        fiscalFoundList: [],
        idFiscalFund: "",
        idProduct: "",
        idStock: "",
        quantity: "",
        supplierList: [],
        idSupplier: null,
        loadingProducts: false,
        loadingLot: false,
        loadingSupplier: false,
      },

      /* Crear Merma */
      shippingReasonModel: {
        shippingReason: 0,
        reason: 0,
      },
      shippingReasonList: [],
      /* pagesAtributes */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
        sortFilters: {}
      },
      listStartingOrder: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,

      /* Cargas */
      sending: false,
      isExist: false,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      if (this.action == 1) {
        return "decrease_order.starting_order_reason";
      } else if (this.action == 2 && this.idOrder == "") {
        return "decrease_order.create_order_reason";
      } else if (this.action == 2 && this.idOrder != "" && this.isExist) {
        return "decrease_order.create_order_existing_reason";
      } else if (this.action == 2 && this.idOrder != "") {
        return "decrease_order.update_order_reason";
      }
      return "Listado " + this.orderIdT;
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    /* General */
    showAction(action) {
      return this.action == action;
    },
    async changeAction(action) {
      if (action == 1) {
        this.action = action;
        this.clean(0);
      } else if (action == 2) {
        /* Verifico si existe alguna merma pendiente */
        this.destinationId = "";
        this.action = action;
        this.isExist = false;
        this.orderExists();
      }
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    //Funcion que se encarga de cambiar la sucursal de origen
    branchDestinationChange(destinationId, val) {
      this.destinationId = val
        ? parseInt(destinationId)
        : destinationId.idBranchOffice;
    },
    allShipmentRequestChange(idShimentRequest) {
      this.idShimentRequest = idShimentRequest;
    },
    //Verifio si existe algún orden de tipo merma que se encuentre pendiente de finalizar
    async orderExists() {
      this.sending = true;
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.validateShipmentAndOrder({
        type: 2,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then(async (response) => {
          let { success, data } = response.data.data;
          if (success) {
            this.isExist = true;
            //Guardo el id de la orden
            this.idOrder = data.idOrder;
            //Guardo la el motivo de la merma
            this.shippingReasonModel.shippingReason = data.shippingReason;
            this.shippingReasonModel.reason = data.shippingReason;
            this.oldDestinationId = "";
            //Selecciono el destino del envio
            this.$refs.allShipmentRequest.reloadComponentClean();
            this.idShimentRequest = null;
            if (data.shipmentRequest != undefined) {
              this.$refs.allShipmentRequest.reloadComponentIdShimentRequest(
                data.shipmentRequest.idShipmentRequest
              );
              this.idShimentRequest = data.shipmentRequest.idShipmentRequest;
            }
            this.destinationId = data.destination;
            data.shippingReason == 9
              ? this.$refs.branchOfficeDestinationSupplier.reloadComponentIdBranchOffice(
                  data.destination
                )
              : this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(
                  data.destination
                );
            this.loading.hide();
            await this.orderDetail({
              idOrder: this.idOrder,
            });
            this.isUpdate = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
          //Cambio a modo crear merma
          this.action = 2;
        });
    },
    updateOrder(item) {
      this.sending = true;
      this.isExist = false;
      this.isUpdate = true;
      //Guardo el id de la orden
      this.idOrder = item.orderId;
      this.idShimentRequest = null;
      //Guardo la el motivo de la merma
      this.shippingReasonModel.shippingReason = item.shippingReason;

      //this.shippingReasonModel.reason = item.shippingReason;
      item.shippingReason == 9
        ? this.$refs.branchOfficeDestinationSupplier.reloadComponentIdBranchOffice(
            item.branchOfficeDestinationId
          )
        : this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(
            item.branchOfficeDestinationId
          );

      this.$refs.allShipmentRequest.reloadComponentClean();
      if (item.shipmentRequest != undefined) {
        this.$refs.allShipmentRequest.reloadComponentIdShimentRequest(
          item.shipmentRequest.idShipment
        );
        this.idShimentRequest = item.idShipment;
      }
      this.destinationId = item.branchOfficeDestinationId;
      this.oldDestinationId = item.branchOfficeDestinationId;

      this.orderDetail({
        idOrder: item.orderId,
      });
      this.sending = false;
      this.action = 2;
    },

    //Se encarga de agregar el envio tipo merma
    addShipmentTypeDecrease() {
      if (this.destinationId == this.branchOfficeState.idBranchoffice) {
        this.alert("error", "Error_Branch_Office_0003");
      } else if (
        this.shippingReasonModel.shippingReason != "" &&
        this.destinationId !== undefined &&
        this.destinationId !== ""
      ) {
        //Deshabilito todos los botones hasta esperar que termino el envio
        this.sending = true;
        //Mustro la pantalla de cargando
        this.loading = UtilFront.getLoadding();
        //Deshabilito el select de sucursales de destino
        this.$refs.branchOfficeDestination.reloadComponentDisabled(true);
        ShipmentsRequest.addShipmentWithOrderTypeDecrease({
          destination: this.destinationId,
          shippingReason: this.shippingReasonModel.shippingReason,
          type: 2,
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
          shipmentRequest: {
            idShipmentRequest:
              this.idShimentRequest == "" ? null : this.idShimentRequest,
          },
        })
          .then((response) => {
            let { success, message, data } = response.data.data;
            if (success) {
              //Almaceno el id de la orden generado ( Nuevo registro )
              this.idOrder = data.idOrder;
              //Cambio la accion llenar lista de productos
              this.action = 2;
              this.updateAction(2);
              this.shippingReasonModel.shippingReason = this.shippingReasonModel.shippingReason;
              this.shippingReasonModel.reason = this.shippingReasonModel.shippingReason;
              this.alert("success", "Add_Product_0004");
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.sending = false;
            this.isUpdate = true;
            this.$refs.branchOfficeDestination.reloadComponentDisabled(false);
            this.loading.hide();
          });
      } else {
        this.alert("error", "Message_Branch_0001");
      }
    },
    getFiscalFund() {
      FiscalFoundRequest.listAllFiscalFundByBranchOfficeForType({
        type: this.shippingReasonModel.shippingReason,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.fiscalFoundList = data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        });
    },
    getProductsBySupplier() {
      this.clean(3);
      this.modelStartingOrder.loadingProducts = true;
      ProductResquest.findAllProductosByStockByType({
        shipmentsShippingReason: this.shippingReasonModel.shippingReason,
        fiscalFundId: this.modelStartingOrder.idFiscalFund,
        typeModule: 2,
        typeWarehouse: this.shippingReasonModel.shippingReason != 12 ? 1 : 2,
        remissionRequisitionId: this.modelStartingOrder.idSupplier,
        idUser: this.idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.productsList = data;
            this.modelStartingOrder.loadingProducts = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingProducts = false;
        });
    },
    //Recupero los productos por fuente de financiamiento, sucursal y motivo de merma
    getProductsByFiscalFound() {
      this.modelStartingOrder.loadingSupplier = true;
      this.clean(3);
      if (this.modelStartingOrder.idFiscalFund == null) {
        this.modelStartingOrder.loadingSupplier = false;
        return;
      }
      this.getProductsBySupplier();
      ShipmentsRequest.listSupplierByFinancing({
        idFiscalFund: this.modelStartingOrder.idFiscalFund,
        //type: shippingReasonModel.shippingReason,
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      })
        .then((response) => {
          const { data } = response;
          if (data) {
            this.modelStartingOrder.loadingSupplier = false;
            this.modelStartingOrder.supplierList = data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingSupplier = false;
        });
    },
    getLotsByProduct() {
      this.clean(4);
      this.modelStartingOrder.loadingLot = true;
      if (this.modelStartingOrder.idProduct == null) {
        this.modelStartingOrder.loadingLot = false;
        return;
      }
      LotRequest.findAllLotsByType({
        shipmentsShippingReason: this.shippingReasonModel.shippingReason,
        fiscalFundId: this.modelStartingOrder.idFiscalFund,
        typeModule: 2,
        typeWarehouse: this.shippingReasonModel.shippingReason != 12 ? 1 : 2,
        productId: this.modelStartingOrder.idProduct,
        idUser: this.idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
      })
        .then((response) => {
          const { success, data } = response.data.data;
          if (success) {
            this.modelStartingOrder.lotList = data;
            this.modelStartingOrder.loadingLot = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.modelStartingOrder.loadingLot = false;
        });
    },
    getLots() {
      this.clean(5);
    },
    //Funcion que se encarga de agregar los productos a la orden
    addStockInOrder() {
      if (this.modelStartingOrder.quantity != "") {
        this.loading = UtilFront.getLoadding();
        StockRequest.updateStockWithOrderStock({
          order: {
            idOrder: this.idOrder,
          },
          stock: {
            idStock: this.modelStartingOrder.idStock,
            quantity: this.modelStartingOrder.quantity,
          },
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
        })
          .then(async (response) => {
            let { data } = response.data;
            let { success, message } = data;
            if (success) {
              this.alert("success", "Add_Product_0003");
              this.loading.hide();
              await this.orderDetail({ idOrder: this.idOrder });
              this.getProductsByFiscalFound();
            } else {
              this.alert("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("warning", "Por el momento no se pudo atender la transacción, por favor intente de nuevo.");
          })
          .finally(() => {
            this.loading.hide();
          });
      } else {
        this.alert("error", "Error_Product_Quantity_0001");
      }
    },
    async orderDetail(item) {
      this.loading = UtilFront.getLoadding();
      await OrderStockRequest.orderStockDetail({
        idOrder: item.idOrder,
        status: this.shippingReasonModel.shippingReason != 12 ? [1, 4] : [1],
      })
        .then((response) => {
          let { status, data } = response;
          if (status == 200) {
            this.orderStockProducts = data;
            this.$refs.tableOrderStock.reloadComponent(data);
          } else if (status == 204) {
            this.$refs.tableOrderStock.reloadComponent([]);
            this.alert("warning", data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async deleteRecord(item) {
      const { isConfirmed } = await SwalDelete.fire({
        title: this.$t("starting_order.title_delete"),
        html:
          '<div class="card">' +
          '<div class="card-body">' +
          '<div class="row">' +
          '<div class=" col-md-12"><b>' +
          "<i class='feather icon-info mr-2 text-fmedical-blue'></i>Fuente de Financiamiento: " +
          "</b>" +
          item.fiscalFoundKey +
          "</div>" +
          '<div class="col-md-6"><b>' +
          "<i class='feather icon-copy mr-2 text-fmedical-blue'></i>Cantidad:" +
          "</b>" +
          item.quantity +
          "</div>" +
          '<div class=" col-md-6"><b>' +
          "<i class='feather icon-grid mr-2 text-fmedical-blue'></i>Lote: " +
          "</b>" +
          item.lot +
          "</div>" +
          '<div class="col-md-12"><b>' +
          "<i class='feather icon-package mr-2 text-fmedical-blue'></i>Causes: " +
          "</b>" +
          item.productKey +
          "</div>" +
          '<div class="col-md-12"><b>' +
          "<i class='feather icon-calendar mr-2 text-fmedical-blue'></i>Fecha de caducidad: " +
          "</b>" +
          item.expirationDate +
          "</div>" +
          "<div class='col-md-12'><b>" +
          "<i class='feather icon-align-center mr-2 text-fmedical-blue'></i>Producto: " +
          "</b>" +
          item.productDescription +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.sending = true;
      //Mustro la pantalla de cargando
      this.loading = UtilFront.getLoadding();
      OrderStockRequest.disableOrderStock({
        idOrderStock: item.idOrderStock,
        userId: this.idUser,
        branchOfficeId: this.branchOfficeState.idBranchoffice,
      })
        .then(async (response) => {
          //let { success, message } = response.data.data;
          if (response.status == 200) {
            this.loading.hide();
            this.alert("success", "Success_Product_Delete_0001");
            await this.orderDetail({ idOrder: this.idOrder });
          } else if (response.status == 202) {
            this.alert("error", response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", "Error_Product_Delete_0001");
        })
        .finally(() => {
          //Habilito los botones finalizo el envio
          this.sending = false;
          //Elimino la pantalla de cargando
          this.loading.hide();
          this.clean(1);
          //Cierro el modal de eliminar
          this.dialogDelete = false;
        });
    },
    clean(level) {
      if (level == 0) {
        this.idOrder = "";
        this.$refs.allShipmentRequest.reloadComponentClean();
        this.idShimentRequest = null;
        this.shippingReasonModel.shippingReason = 0;
        this.$refs.branchOfficeDestination.reloadComponentClean();
        this.isUpdate = false;
      }
      if (level <= 1) {
        this.modelStartingOrder.idFiscalFund = "";
      }
      if (level <= 2) {
        //Se encarga de limpiar la lista de productos el item seleccionado
        this.modelStartingOrder.supplierList = [];
        this.modelStartingOrder.idSupplier = null;
      }
      if (level <= 3) {
        //Se encarga de limpiar la lista de lotes y el item seleccionado
        this.modelStartingOrder.idProduct = "";
        this.modelStartingOrder.productsList = [];
      }
      if (level <= 4) {
        //Se encarga de limpiar los campos de cantidad
        this.modelStartingOrder.lotList = [];
        this.modelStartingOrder.idStock = "";
      }
      if (level <= 5) {
        this.modelStartingOrder.quantity = "";
      }
      if (this.action == 2) {
        this.$refs.startingOrderFormComponent.resetForm();
      }
    },
    //Esta funcion se encarga de actualizar la sucursal de destino y el motivo de la merma
    //En caso de ser modificacion de la merma, solo se actualiza el destino
    async finishDecrease() {
      if (this.destinationId == this.branchOfficeState.idBranchoffice) {
        this.alert("error", "Error_Branch_Office_0003");
      } else if (this.destinationId > 0) {
        const { isConfirmed } = await SwalConfirm.fire({
          title: this.$t("decrease_order.title_modal_confirm"),
          text: this.$t("decrease_order.subtitle_modal_confirm"),
        });
        if (!isConfirmed) {
          return;
        }
        //Deshabilito todos los botones hasta esperar que termino el envio
        if (!this.sending) {
          this.loading = UtilFront.getLoadding();
          this.sending = true;
          //Mustro la pantalla de cargando
          let modalData = {
            orderId: this.idOrder,
            destination: this.destinationId,
            userBranchOffice: {
              idUser: this.idUser,
              idBranchOffice: this.branchOfficeState.idBranchoffice,
            },
            shipmentRequest: {
              idShipmentRequest:
                this.idShimentRequest == "" ? null : this.idShimentRequest,
            },
          };
          if (!this.isUpdate)
            modalData.shippingReason = this.shippingReasonModel.shippingReason;
          ShipmentsRequest.finishDecrease(modalData)
            .then( async (response) => {
              let { success, message } = response.data.data;
              if (success) {
                this.loading.hide();
                this.alert("success", "Success_Send_0001");
                await this.listAllShipmentsByTypeDecrease(this.pagesAtributes);
                this.changeAction(1);
              } else {
                this.alert("error", message);
              }
            })
            .catch((error) => {
              console.log(error);
              this.alert("internal");
            })
            .finally(() => {
              this.sending = false;
              this.loading.hide();
            });
        }
      } else {
        this.alert("error", "Message_Branch_Destination_0001");
      }
    },
    async listAllShipmentsByTypeDecrease(page) {
      this.loading = UtilFront.getLoadding();
      await ShipmentsRequest.listAllShipmentsByType({
        type: 2,
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOfficeState.idBranchoffice,
        page,
      })
        .then((response) => {
          let { data } = response;
          this.listStartingOrder = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    //Funcion que se encarga de limpiar la orden
    async cleanOrder() {
      const { isConfirmed } = await SwalConfirm.fire({
        title: "¿Estás seguro de cambiar el motivo?",
        text:
          "Al cambiar el motivo, se vaciara la lista de productos y se aplicaran " +
          "filtro a los campos de fuente de financiamiento, productos y lotes.",
      });
      if (!isConfirmed) {
        this.shippingReasonModel.shippingReason = this.shippingReasonModel.reason;
        return;
      }
      if (!this.sending) {
        this.sending = true;
        //Mustro la pantalla de cargando
        this.loading = UtilFront.getLoadding();
        OrderRequest.orderChangeShipping({
          idOrder: this.idOrder,
          shippingReason: this.shippingReasonModel.shippingReason,
          userBranchOffice: {
            idUser: this.idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
        })
          .then((response) => {
            let { success } = response.data.data;

            if (success) {
              this.alert("succes", "Success_Change_0001");
              //Cambio el valor anterior de la razón
              this.shippingReasonModel.reason = this.shippingReasonModel.shippingReason;
              //Limpio los inputs
              this.clean(1);
              this.$refs.tableOrderStock.reloadComponent([]);
            } else {
              this.alert("error", "Error_Change_0001");
            }
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            //Habilito los botones finalizo el envio
            this.sending = false;
            //Elimino la pantalla de cargando
            this.loading.hide();
            //Cierro el modal
            this.dialogConfirm = false;
          });
      }
    },
    detail(item) {
      this.$refs.modalOrderDetail.reloadComponent(item);
    },
    downloadPDF(item) {
      let send =
        "idOrder=" + item.orderId + "&" + "idShipment=" + item.idShipment;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Generando documento (documento.pdf)");
    },
    excelTable() {
      this.$toast.success("Reporte generado (documento.xlsx)");
      ExcelRequest.reportOrdersDecrease({
        type: 2,
        userBranchOffice: {
          idUser: this.idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
      });
    },
  },
  watch: {
    async branchOfficeState() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
        sortFilters: {}
      };
      await this.listAllShipmentsByTypeDecrease(this.pagesAtributes);
      this.$nextTick(() => {
        this.showTable = true;
      });
      this.getFiscalFund();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.decreaseListHeaders = DECREASE();
      this.orderHeaders = ORDER_DETAIL_ACTION();
    },
    "modelStartingOrder.idFiscalFund": function() {
      this.modelStartingOrder.idSupplier = null;
    },
    "shippingReasonModel.shippingReason": function() {
      if (this.isExist || this.isUpdate) return;
      this.destinationId = "";
      this.$refs.branchOfficeDestinationSupplier.reloadComponentIdBranchOffice(
        null
      );
      this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(null);
    },
  },
  created() {
    /*  Asigan el valor del state para sucursal global */
    this.updateAction(1);
    this.isExist = false;
    //Recupero el usuario
    let infoUser = UtilFront.getDataUser();
    this.idUser = infoUser.idUser;
    this.getFiscalFund();

    /*
     * Los valores 7 y 8 solo se utilizan si es necesario hacer un filtro al modulo
     * no usar dentro del catalogo de enums
     */
    this.shippingReasonList = [
      {
        text: "Autorización",
        value: 1,
      },
      {
        text: "Caducidad",
        value: 2,
      },
      {
        text: "Carta Canje",
        value: 3,
      },
      {
        text: "Deterioro",
        value: 4,
      },
      {
        text: "Extravío",
        value: 5,
      },
      {
        text: "Error de surtido",
        value: 6,
      },
      {
        text: "Devolución a Proveedor",
        value: 9,
      },
      {
        text: "Destrucción",
        value: 10,
      },
      {
        text: "Nota de Crédito",
        value: 11,
      },
      {
        text: "Alerta Sanitaria",
        value: 12,
      },
      {
        text: "Donación",
        value: 13,
      },
      //{ text: "Modulo de Salidas ( No tocar )", value:7}, ( No tocar )
      //{ text: "Modulo de Dispensacion ( No tocar )", value:8} ( No tocar )
    ];
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectBranchOffice,
    AllBranchOfficeComponent,
    AlertMessageComponent,
    DecreaseTableComponent,
    ModalDeleteProductComponent,
    ModalConfirmOrderComponent,
    ModalOrderProductComponent,
    AllShipmentRequest,
    StartingOrderFormComponent,
    AllBranchOfficeSupplier,
    ProductsTableComponent,
  },
};
</script>
